<template>
  <div id="app">
    <dirk
      v-if="isAuthorized && !needsMfa"
      :is-development="isDevelopment"
      :session-id="sessionId"
      @set:flash="flash = $event"
      @logout="logout"
    />

    <login
      v-else
      :needs-mfa="needsMfa"
      :settings-url="settingsUrl"
      :is-ory="isOry"
      :loginUrl="loginUrl"
    />

    <flash v-if="flash" :flash="flash" @dismissed="flash = null" />
  </div>
</template>

<script setup lang="ts">
  import { onBeforeUnmount, onMounted, ref, readonly } from 'vue'

  import Dirk from './components/Dirk.vue'
  import Flash from '@/components/Flash.vue'
  import Login from './components/Login.vue'

  import Cookies from 'js-cookie'
  const loginUrl = import.meta.env.VITE_LOGIN_URL
  const settingsUrl = import.meta.env.VITE_SETTINGS_URL

  const checkAuthorizedInterval = ref<number>(0)
  const isOry = ref(true)
  const flash = ref<null | Flash>(null)
  const isAuthorized = ref(false)

  const isDevelopment = import.meta.env.DEV
  const needsMfa = ref(false)

  const sessionId = ref<string | null>(null)

  const logout = async () => {
    removeSecureCookie('legacy')

    if (isOry.value) {
      const resp = await fetch(import.meta.env.VITE_LOGOUT_URL, {
        credentials: 'include',
      })

      const data = await resp.json()
      document.location.href = data.logout_url
    } else {
      document.location.href = import.meta.env.VITE_LEGACY_LOGOUT_URL
    }
  }

  const clearAuthorizedInterval = () => {
    clearInterval(checkAuthorizedInterval.value)
  }

  const checkIfAuthorized = async () => {
    const url = isOry.value
      ? import.meta.env.VITE_STATUS_URL
      : import.meta.env.VITE_LEGACY_STATUS_URL

    try {
      const resp = await fetch(url, { credentials: 'include' })
      const data = (await resp.json()) as SessionData

      if (resp.ok) {
        sessionId.value = data.id

        isAuthorized.value = data.active

        if (isOry.value && data.authenticator_assurance_level === 'aal1') {
          needsMfa.value = true
        }
      } else {
        if (isOry.value && data.error.id === 'session_aal2_required') {
          const returnTo = encodeURIComponent(window.location.href)
          window.location.href = data.error.details.redirect_browser_to + `&return_to=${ returnTo }`
        }
      }
    } catch (e) {
      console.error(e)

      isAuthorized.value = false
    }

    if (!isAuthorized.value) {
      clearAuthorizedInterval()
      flash.value = { message: 'U moet eerst inloggen', kind: 'info' }
    } else {
      checkAuthorizedInterval.value = setTimeout(checkIfAuthorized, 60000)
    }
  }

  const cookieName = (name: string) => {
    return isDevelopment ? name : `__Host-${name}`
  }

  const getSecureCookie = (name: string) => {
    return Cookies.get(cookieName(name))
  }

  const setSecureCookie = (name: string, value: string) => {
    if (getSecureCookie(name)) {
      return true
    }

    const expires = new Date()
    expires.setHours(expires.getHours() + 2)

    Cookies.set(cookieName(name), value, {
      secure: !isDevelopment,
      sameSite: 'Lax',
      path: '/',
      expires,
    })
  }

  const removeSecureCookie = (name: string) => {
    Cookies.remove(cookieName(name), {
      secure: !isDevelopment,
      sameSite: 'Lax',
      path: '/',
    })
  }

  onBeforeUnmount(clearAuthorizedInterval)

  const authSetup = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const isLegacy = urlParams.get('legacy') || getSecureCookie('legacy')

    if (isLegacy) {
      setSecureCookie('legacy', 'true')
      isOry.value = false
    }

    setTimeout(() => {
      window.history.replaceState({}, document.title, window.location.pathname)
    }, 100)

    checkIfAuthorized()
  }

  onMounted(authSetup)
</script>

<style lang="scss">
  body,
  html {
    @apply bg-beige;
  }

  #app {
    @apply font-sans leading-3 text-[#2c3e50];
  }
</style>
